<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit-functions'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-functions",
              "resource-functions"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section"
      v-if="activeResourceFunctionTypes && activeResourceFunctionTypes.length"
    >
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
        v-for="(functionType, idx) in activeResourceFunctionTypes"
        :key="functionType.id"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  :id="`resourceFunction${functionType.id}`"
                  type="checkbox"
                  v-model="functionType.active"
                  @change="
                    resourceFunctionTypes.data[idx].active =
                      functionType.active;
                    activateFunction(functionType.active, functionType.id);
                  "
                />
                <label :for="`resourceFunction${functionType.id}`"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              <router-link
                :to="{
                  name: forwardLinkName,
                  params: { function_id: functionType.id }
                }"
              >
                {{ functionType.name }}
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </router-link>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ResourceFunctions",
  mixins: [helpOnlineMixin],
  data() {
    return {
      resourceTypeIsActive: false,
      helpSlug: "resources-functions"
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceFunctionTypes",
      "resourceResourceFunctions"
    ]),
    ...mapGetters("resource", ["activeResourceFunctionTypes"])
  },
  mounted() {
    const { resource } = this;

    if (
      resource &&
      resource.data &&
      this.canItem(this.resource.data, "update")
    ) {
      this.getResourceResourceFunctions(resource.data.id);
    } else {
      this.$router.push({ name: "r_resources" });
    }
  },
  methods: {
    ...mapActions("resource", ["getResourceResourceFunctions"]),
    isActive(id) {
      const { resourceResourceFunctions } = this;
      if (
        resourceResourceFunctions &&
        resourceResourceFunctions.data &&
        resourceResourceFunctions.data.length
      ) {
        const filtered = resourceResourceFunctions.data.filter(
          item => item.resource_function_type_id === id
        );
        if (filtered && filtered.length) {
          return filtered[0].active;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    activateFunction(value, id) {
      const { resourceResourceFunctions } = this;
      const filtered = resourceResourceFunctions.data.filter(
        item => item.resource_function_type_id === id
      );
      if (filtered && filtered.length) {
        filtered[0].active = Number(value);
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        httpServiceAuth
          .put(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/resourcefunctions/${filtered[0].id}`,
            filtered[0]
          )
          .then(() => {
            this.getResourceResourceFunctions(this.resource.data.id);
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      } else {
        this.$router.push({
          name: this.forwardLinkName,
          params: { function_id: id }
        });
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    forwardLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
